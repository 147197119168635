import { getTPASettingsIsPostPageSplitEnabled } from '@wix/communities-blog-client-common';
import { useSelector } from '../../../common/components/runtime-context';
import { getCurrentMatch } from '../../../common/router/router-selectors';

export type MatchedRoute = ReturnType<typeof getCurrentMatch> & {
  isPostPageSplitEnabled: boolean;
};

export const useMatchedRoute = () =>
  useSelector((state): MatchedRoute => {
    return {
      ...getCurrentMatch(state),
      isPostPageSplitEnabled: getTPASettingsIsPostPageSplitEnabled(state),
    };
  });
